<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div>
      <section class="fdb-block">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <img alt="image" class="img-fluid" src="/images/notfound.png">
              </div>
              <div class="col-12 col-md-6 col-lg-5 ml-md-auto text-left">
                <p style="font-size:40px;color:#5c0513;font-weight:bold">Página no encontrada.</p>
                <p style="font-size:20px;color:black;font-weight:normal">La página buscada no existe. Por favor verifique la dirección en la barra de direcciones de su navegador.</p>
              </div>
            </div>
          </div>
        </section>    
    </div>
  </section>
</template>
<script>
export default {
    
}
</script>
<style>
#container {
  position: absolute;
  overflow: hidden;
  background: #cdcfd4;
  height: 100%;
  width: 100%;
  /* border: 8px solid red; */
  padding: 50px 50px;
  vertical-align: middle;
  text-align: center;
 }

#left_panel {
  /* position: relative; */
  overflow: scroll;
  float: left;
  /*background: gray;*/
  /*height: 400px;*/
  width: 50%;
  /* left: 0px; */
  /* border: 2px solid #c9c4ca;*/
  padding: 50px 50px;
 }

#right_panel {
  position: relative;
  overflow: scroll;
  float: right;
  /*background: white;*/
  /*height: 400px;*/
  width: 50%;
  /* right: 0px; */
  /*border: 2px solid #c9c4ca;*/
  padding: 50px 50px;
 }

</style>